@tailwind base;
@tailwind components;
@tailwind utilities;

/* Basic editor styles */
.tiptap {
    :first-child {
      margin-top: 0;
    }
  
    img {
      display: block;
      height: auto;
      margin: 1.5rem 0;
      max-width: 100%;
  
      &.ProseMirror-selectednode {
        outline: 3px solid var(--purple);
      }
    }
  }